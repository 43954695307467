<template>
	<div>
		<div class="content_title" v-for="(article,index) in articles" :key="id">
			<div class="pictures">

			</div>
			<div class="words" @click="queryArticle(article.id)">
				<div class="words_title">
					{{article.title}}
				</div>
				<div class="words_content" v-html="article.content">
					
				</div>
				<div class="words_message">
					时间：{{article.releaseTime}} &nbsp; &nbsp;
				</div>
			</div>
		</div>
		<div class="load_more" @click="loadingMore">
			<p>加载更多</p>
		</div>
	</div>
</template>

<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import { computed } from 'vue'
	import { useStore } from 'vuex'
	
	export default {
		name: 'ArticleList',
		setup() {
			const store = useStore()
			const router = useRouter()
			const route = useRoute()
			
			function loadingMore(){
				alert("抱歉，到底了 QAQ")
			}
			
			function queryArticle(id){
				router.push('/article/show?aid='+id)
			}

			return {
				articles:computed(() => store.state.articleList.filter(article=>{
					return article.id <= 3
				})),loadingMore,queryArticle
			}
		}
	}
</script>

<style>
	.content_title {
		margin-top: 20px;
		width: 100%;
		height: 360px;
		border-radius: 10px;
		background-color: #54296E;
	}

	.pictures {
		width: 100%;
		height: 160px;
		border-radius: 10px;
		background-image: url(../assets/heardPhoto.jpg);
		animation-name: pic1;
		-webkit-animation-name: pic;
		-webkit-animation-duration: 2s;
	}

	@-webkit-keyframes pic {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}

	@-webkit-keyframes pic1 {
		from {
			opacity: 1;
		}

		to {
			opacity: 0.5;
		}
	}

	.words {
		width: 100%;
		height: 200px;
		cursor: pointer;
	}

	.words_title {
		width: 100%;
		height: 60px;
		line-height: 60px;
		font-size: 24px;
		text-indent: 5%;
		font-weight: bold;
		color: lightgray;
		overflow: hidden;
	}

	.words_content {
		width: 90%;
		height: 100px;
		margin-left: 5%;
		font-size: 18px;
		line-height: 30px;
		font-family: "宋体";
		font-weight: 600;
		color: lightgray;
		overflow: hidden;
	}

	.words_message {
		width: 90%;
		height: 40px;
		margin-left: 5%;
		line-height: 40px;
		font-family: "宋体";
		font-weight: 600;
		color: lightgray;
		text-align: right;
		overflow: hidden;
	}
	.load_more{
		width: 100%;
		height: 66px;
		background-color: #54296E;
		border-radius: 10px;
		text-align: center;
		line-height: 66px;
		font-size: 24px;
		margin-top: 20px;
		color: white;
		cursor: pointer;
	}
</style>
