<template>
	<div class="popular_articles">
		<div class="articles_title">
			文章top榜
		</div>
		<ul>
			<li v-for="(article,index) in topList" :key="id">
				<img src="../assets/popular.png">&nbsp;
				<router-link :to="`/article/show?aid=${article.id}`">{{article.title}}</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	import { computed } from 'vue'
	import { useStore } from 'vuex'
	export default {
		name: 'PopularArticle',
		setup() {
			
			const store = useStore()
			
			return{
				topList:computed(() => store.state.articleList.filter(article=>{
					return article.id <= 5
				}))
			}
		}
	}
</script>

<style>
	/* 文章top榜 */
	.popular_articles {
		width: 100%;
		height: 400px;
		margin-top: 30px;
		border-bottom: 2px solid gray;
	}

	.articles_title {
		width: 100%;
		height: 50px;
		font-size: 24px;
		font-family: "宋体";
		line-height: 50px;
		font-weight: bold;
		text-indent: 5%;
	}

	.popular_articles li {
		list-style-type: none;
		text-indent: 6%;
		width: 100%;
		height: 70px;
		line-height: 60px;
	}

	.popular_articles li img {
		/* 使li里面的文字和图片垂直居中 */
		vertical-align: middle;
	}

	.popular_articles li a {
		text-decoration: none;
		color: #000000;
	}

	.popular_articles li a:hover {
		text-decoration: none;
		color: white;
	}
</style>
