<template>
	<div class="part_one">
		<div class="part_one_1">
			分支
		</div>
		<ul>
			<li>
				<router-link to="/drafts">站长动向</router-link>
			</li>
			<li>
				<router-link to="/bookcase">三味书屋</router-link>
			</li>
			<li>
				<router-link to="/resources">小站开源</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'ParishBranches',

	}
</script>

<style>
	.part_one {
		width: 100%;
		height: 200px;
		border-bottom: 2px solid gray;
	}

	.part_one_1 {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-indent: 1em;
		font-size: 20px;
		font-weight: 400;
		color: #8A2BE2;
	}

	.part_one li {
		list-style-type: none;
		width: 100%;
		height: 50px;
		font-size: 20px;
		font-weight: bold;
		color: #8A2BE2;
		line-height: 50px;
		text-align: center;
	}

	.part_one li a {
		text-decoration: none;
	}

	.part_one li:hover {
		background-color: #8D377E;
	}
	
	.part_one li:hover a{
		color: white;
	}
</style>
