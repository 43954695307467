<template>
	<div id="all">
		<ParishHeader></ParishHeader>
		
		<div class="resource_body">
			<div class="res_title">
				页面动效
			</div>
			<div class="res_body">
				<p class="res_content">非常抱歉，耽误您宝贵的几秒钟，该功能暂未实现,haha</p>
				<p class="res_content">用于设置鼠标样式，页面背景特效的开启与关闭</p>
			</div>
		</div>
		
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"

	export default {
		name: 'PageDynamics',
		components: {
			ParishHeader,
			ParishFooter
		},
		setup() {

		}
	}
</script>

<style>
</style>
