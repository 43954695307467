// 该文件专门用于创建整个应用的路由器
import {
	createRouter,
	createWebHistory
} from 'vue-router'
//引入组件
import ParishRoom from '../components/ParishRoom'
import LetterPen from '../components/LetterPen'
import MessageBoard from '../components/MessageBoard'
import Tracks from '../components/Tracks'
import Aboutme from '../components/Aboutme'
import Drafts from '../components/Drafts'
import Bookcase from '../components/Bookcase'
import Resources from '../components/Resources'
import BackgroundMusic from '../components/BackgroundMusic'
import PageDynamics from '../components/PageDynamics'
import PageWizard from '../components/PageWizard'
import Message from '../components/Message'
import Article from '../components/Article'

//创建并暴露一个路由器
export default new createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/index',
			component: ParishRoom
		},
		{
			path: '/letterPen',
			component: LetterPen
		},
		{
			path: '/messageBoard',
			component: MessageBoard
		},
		{
			path: '/tracks',
			component: Tracks
		},
		{
			path: '/aboutme',
			component: Aboutme
		},
		{
			path: '/drafts',
			component: Drafts
		},
		{
			path: '/bookcase',
			component: Bookcase
		},
		{
			path: '/resources',
			component: Resources
		},
		{
			path: '/backgroundMusic',
			component: BackgroundMusic
		},
		{
			path: '/pageDynamics',
			component: PageDynamics
		},
		{
			path: '/pageWizard',
			component: PageWizard
		},
		{
			path: '/message/:id',
			component: Message,
			props: true
		},
		{
			path: '/article/:id',
			component: Article,
			props: true
		}
	]
})
