import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 引入路由配置
import router from './router'
import store from './store/index.js'

const app = createApp(App)
app.config.productionTip = false
// 屏蔽警告信息
app.config.warnHandler = () => null;
app.use(store).use(router).use(ElementPlus).mount('#app')
