<template>
	<div id="all">
		<!-- 头部 -->
		<ParishHeader></ParishHeader>
		<div class="resource_body">
			<div class="res_title">
				文章
			</div>
			<div class="res_body">
				<div class="mes_box">
					
				</div>
				<p class="art_title">{{artitle[0].title}}</p>
				<div class="mes_box">
					
				</div>
				<p class="art_content" v-html="artitle[0].content"></p>
				<div class="mess_footer">
					{{artitle[0].releaseTime}}
				</div>
			</div>
		</div>
		<!-- 尾部 -->
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import { computed } from 'vue'
	import { useStore } from 'vuex'
	
	export default{
		name:'Article',
		props:['id'],
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			const store = useStore()
			const route = useRoute()
			
			return{
				artitle:computed(() => store.state.articleList.filter(article=>{
					return article.id == route.query.aid
				}))
			}
		}
	}
</script>

<style>
	.art_title{
		font-size: 30px;
		text-align: center;
		font-weight: bold;
		font-family: "楷体";
	}
	.art_content{
		width: 90%;
		margin-left: 5%;
		font-size: 20px;
		text-indent: 2rem;
		margin-left: 2%;
	}
	.art_content img{
		width: 50%;
	}
</style>