<template>
	<div class="self-introduction">
		<div style="width: 100%; height: 10%;">

		</div>
		<div class="head_portrait">

		</div>
		<div class="describe">
			<p class="describe_name">{{author.name}}</p>
			<p class="desc">{{author.describe}}</p>
		</div>
	</div>
</template>

<script>
	import {
		reactive,ref
	} from 'vue'
	export default {
		name: 'ParishAuthor',
		setup() {
			let author = reactive({
				name: ref('进步的花菜'),
				describe: ref('不积跬步无以至千里')
			})
			return {
				author
			}
		}
	}
</script>

<style>
	/* 简介 */
	.self-introduction {
		width: 100%;
		height: 300px;
		border-bottom: 2px solid gray;
	}

	/* 头像 */
	.head_portrait {
		width: 55%;
		height: 55%;
		margin-left: 20%;
		border-radius: 55%;
		background: url(../assets/heardPhoto.jpg) no-repeat center;
	}

	/* 个人描述 */
	.describe {
		width: 100%;
		height: 30%;
		text-align: center;
	}

	.describe_name {
		width: 100%;
		height: 50px;
		font-size: 20px;
		font-weight: bold;
		line-height: 50px;
		color: blueviolet;
	}

	.desc {
		font-size: 16px;
		font-weight: bold;
		color: gray;
	}
</style>
