<template>
	<div id="all">
		<!-- 头部 -->
		<ParishHeader />

		<!-- 主体 -->
		<div class="messageBoard_main">
			<div class="message_div">

			</div>
			<div class="box_pic">
				<p class="box_pic_title">
					{{poster.title}}
				</p>
				<p class="box_pic_desc">
					{{poster.content}}
				</p>
			</div>
			<div class="message">
				<div class="message_left">
					<ul>
						<li v-for="(message,index) in messages" :key="id">
							<div class="message_box">
								<p class="message_box_title">
									{{message.name}}
								</p>
								<p class="message_box_content">
									{{message.content}}
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="message_right">
					<div class="message_right_title">
						我的简介
					</div>
					<div class="message_right_content">
						<p class="right_name">
							{{introduction.name}}
						</p>
						<p class="right_descrbe">
							{{introduction.description}}
						</p>
						<p class="right_content_type">
							{{introduction.content}}
						</p>

					</div>
					<div class="message_right_contact">
						联系方式
					</div>
					<div class="contact_info">
						<br />
						<p>
							{{introduction.contactInformation}}
						</p>

					</div>
				</div>
			</div>

		</div>

		<!-- 底部 -->
		<ParishFooter />

	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	import { computed } from 'vue'
	import { useStore } from 'vuex'

	export default {
		name: 'MessageBoard',
		components: {
			ParishHeader,
			ParishFooter
		},
		setup() {
			
			const store = useStore()
			
			let poster = {
				title: '我的招贴',
				content: '2023届计算机专业毕业生，新一代打工人，大部分的内容都是以Java学习为主（不过现在的主流是大数据、人工智能，python还是很棒的）'
			}

			let introduction = {
				name: '进步的花菜',
				description:'一个将要步入社会，成为打工人的小伙子',
				content: '文章领域：html、css、js、Java、MySQL',
				contactInformation: 'QQ：'
			}

			return {
				poster,
				messages: computed(() => store.state.messageList),
				introduction
			}
		}
	}
</script>

<style>
	.messageBoard_main {
		width: 90%;
		height: 1750px;
		margin-left: 5%;
		text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
		/* 背景半透明效果 */
		background: hsla(0, 0%, 100%, .5);
	}

	.message_div {
		width: 100%;
		height: 50px;
	}

	.box_pic {
		width: 94%;
		height: 500px;
		background-color: #D3AFFF;
		margin-left: 3%;
		margin-top: 10px;
		border-radius: 20px;
	}

	.box_pic_title {
		width: 100%;
		height: 120px;
		text-align: center;
		font-size: 100px;
		font-family: "楷体";
		color: white;
	}

	.box_pic_desc {
		width: 80%;
		height: 300px;
		margin-top: 40px;
		margin-left: 10%;
		text-align: center;
		font-size: 30px;
		font-family: "楷体";
		color: white;
	}

	.message {
		width: 100%;
		height: 1140px;
		margin-top: 50px;
	}

	.message_left {
		width: 80%;
		height: 1140px;
		float: left;
	}

	.message_left ul {
		width: 100%;
		height: 1140px;
		list-style-type: none;
	}

	.message_left ul li {
		width: 30%;
		height: 200px;
		float: left;
		margin-left: 2%;
		margin-top: 2%;
	}

	.message_box {
		width: 100%;
		height: 200px;
		background-color: #D3AFFF;
		border-radius: 20px;
	}

	.message_box .message_box_title {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-size: 24px;
		color: white;
	}

	.message_box .message_box_content {
		width: 90%;
		height: 140px;
		margin-left: 5%;
		font-size: 16px;
		color: black;
	}

	.message_right {
		width: 20%;
		height: 560px;
		background-color: #BD99BC;
		border-radius: 10px;
		float: left;
		margin-top: 20px;
	}

	.message_right_title {
		width: 90%;
		height: 50px;
		line-height: 50px;
		border-bottom: 2px solid gray;
		font-size: 22px;
		font-weight: bold;
	}

	.message_right_content {
		width: 90%;
		height: 300px;
		line-height: 50px;
		font-size: 18px;
		font-weight: bold;
	}

	.right_name {
		width: 100%;
		height: 50px;
		font-size: 20px;
		font-weight: bold;
		font-family: "楷体";
		text-align: center;
	}

	.right_descrbe {
		width: 100%;
		height: 100px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}

	.right_content_type {
		width: 100%;
		height: 150px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}

	.message_right_contact {
		width: 90%;
		height: 50px;
		line-height: 50px;
		border-bottom: 2px solid gray;
		font-size: 22px;
		font-weight: bold;
	}

	.contact_info {
		width: 90%;
		height: 100px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}
</style>
