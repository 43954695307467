<template>
	<div id="all">
		
		<!-- 头部 -->
		<ParishHeader/>
		<div class="tracks_main">
			<div style="width: 100%; height: 50px;">
				
			</div>
			<div class="tracks_left">
				<div class="tracks_left_title">
					足迹时间线
				</div>
				<div class="timezone">
				    <div class="time">
				        <h2>2021-12-19</h2>
				        <div>
				            <p>页面制作</p>
				            <ul>
								开始制作网站的页面雏形，技术比较单一，简单的网页三剑客（html+css+javascript）
				            </ul>
				        </div>
				    </div>
				    <div class="timeLeft" style="top: 100px;">
				        <h2>2021-12-27</h2>
				        <div>
				            <p>页面雏形完成</p>
				            <ul>
								静态页面制作完成（真实原因：这个时候要交作业了，不能再拖了）
				            </ul>
				        </div>
					</div>
					<div class="timeLeft" style="top: 280px;">
					    <h2>2022-11-26</h2>
					    <div>
					        <p>旧瓶装新酒</p>
					        <ul>
								利用vue将之前原有的页面用vue重新重构了一下（也发现了许多问题，页面高度无法进行弹性伸缩、一些页面效果会丢失...）
					        </ul>
					    </div>
					</div>
					<div class="time" style="top: 320px;">
					    <h2>2022-12-1</h2>
					    <div>
					        <p>部署到阿里云服务器上</p>
					        <ul>
								通过使用node搭建一个简单的服务，将这个小demo放到了服务器上（Linux系统还是那么有高级感）
					        </ul>
					    </div>
					</div>
					<div class="timeLeft" style="top: 470px;">
					    <h2>2022-12-2</h2>
					    <div>
					        <p>网站内容编辑</p>
					        <ul>
								对网站的内容进行一个编写
					        </ul>
					    </div>
					</div>
					<div class="time" style="top: 550px;">
					    <h2>2021-12-3</h2>
					    <div>
					        <p>内容完善</p>
					        <ul>
								将网站内容进行完善（任重而道远）
					        </ul>
					    </div>
					</div>
				</div>
				
			</div>
			
		</div>
		<!-- 尾部 -->
		<ParishFooter/>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Tracks',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			
		}
	}
</script>

<style>
	.tracks_main{
		width: 90%;
		height: 1750px;
		margin-left: 5%;
		text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
		/* 背景半透明效果 */
		background: hsla(0,0%,100%,.5);
	}
	.tracks_left{
		width: 80%;
		height: 1600px;
		border: 1px solid gray;
		background-color: #BD99BC;
		margin-top: 50px;
		border-radius: 30px;
		margin-left: 10%;
		box-shadow: 5px 5px 5px gray;
	}
	.tracks_left_title{
		width: 100%;
		height: 70px;
		text-align: center;
		line-height: 70px;
		font-size: 30px;
		color: #BD428B;
		font-weight: bold;
		font-family: "楷体";
	}
	h1 {
		text-align: center;
		color: #fff;
	}
	.timezone {
		width: 6px;
		height: 1000px;
		background: #D3AFFF;
		margin: 0 auto;
		margin-top: 50px;
		border-radius: 3px;
		position: relative;
		-webkit-animation: heightSlide 2s linear;
	}
	
	@-webkit-keyframes heightSlide {
		0% {
			height: 0;
		}
	
		100% {
			height: 350px;
		}
	}
	
	.timezone:after {
		content: '未完待续...';
		width: 100px;
		color: #54296E;
		position: absolute;
		margin-left: -35px;
		bottom: -30px;
		-webkit-animation: showIn 4s ease;
	}
	
	.timezone .time,
	.timezone .timeLeft {
		position: absolute;
		margin-left: -10px;
		margin-top: -10px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 4px solid rgba(255, 255, 255, 0.5);
		background: #D3AFFF;
		-webkit-transition: all 0.5s;
		-webkit-animation: showIn ease;
	}
	
	.timezone .time:nth-child(1) {
		-webkit-animation-duration: 1s;
	}
	
	.timezone .timeLeft:nth-child(2) {
		-webkit-animation-duration: 1.5s;
	}
	
	.timezone .time:nth-child(3) {
		-webkit-animation-duration: 2s;
	}
	
	.timezone .timeLeft:nth-child(4) {
		-webkit-animation-duration: 2.5s;
	}
	
	@-webkit-keyframes showIn {
	
		0%,
		70% {
			opacity: 0;
		}
	
		100% {
			opacity: 1;
		}
	}
	
	.timezone .time h2,
	.timezone .timeLeft h2 {
		position: absolute;
		margin-left: -120px;
		margin-top: 3px;
		color: #54296E;
		font-size: 14px;
		cursor: pointer;
		-webkit-animation: showIn 3s ease;
	}
	
	.timezone .timeLeft h2 {
		margin-left: 60px;
		width: 100px;
	}
	
	.timezone .time:hover,
	.timezone .timeLeft:hover {
		border: 4px solid #D3AFFF;
		background: #54296E;
		box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.4);
	}
	
	.timezone .time div,
	.timezone .timeLeft div {
		position: absolute;
		top: 50%;
		margin-top: -25px;
		left: 50px;
		width: 300px;
		height: 50px;
		background: #D3AFFF;
		border: 3px solid #8A377E;
		border-radius: 10px;
		z-index: 2;
		overflow: hidden;
		cursor: pointer;
		-webkit-animation: showIn 3s ease;
		-webkit-transition: all 0.5s;
	}
	
	.timezone .timeLeft div {
		left: -337px;
	}
	
	.timezone .time div:hover,
	.timezone .timeLeft div:hover {
		height: 170px;
	}
	
	.timezone .time div p,
	.timezone .timeLeft div p {
		color: #8A377E;
		font-weight: bold;
		text-align: center;
	}
	
	.timezone .time:before,
	.timezone .timeLeft:before {
		content: '';
		position: absolute;
		top: 0px;
		left: 32px;
		width: 0px;
		height: 0px;
		border: 10px solid transparent;
		border-right: 10px solid #8A377E;
		z-index: -1;
		-webkit-animation: showIn 3s ease;
	}
	
	.timezone .timeLeft:before {
		left: -33px;
		border: 10px solid transparent;
		border-left: 10px solid #8A377E;
	}
	
	.timezone .time div ul,
	.timezone .timeLeft div ul {
		list-style: none;
		width: 300px;
		padding: 5px 0 0;
		border-top: 2px solid #8A377E;
		color: #8A377E;
		text-align: center;
	}
	
	.timezone .time div li,
	.timezone .timeLeft div li {
		display: inline-block;
		height: 25px;
		line-height: 25px;
	}
	
</style>