<template>
	<div id="all">
		<ParishHeader></ParishHeader>
		
		<div class="resource_body">
			<div class="res_title">
				小站开源
			</div>
			<div class="res_body">
				<p class="res_content">网站源码(供小伙伴们参考)：<a href="https://pan.baidu.com/s/1Mk8I9cQ_1S4pKVSB2PJc-A?pwd=y43f 提取码：y43f" target="_blank">vue3小项目</a>，内容不是很完善，暂时不上传到github，后续内容相对完善，工程结构更加规范在上传</p>
			</div>
		</div>
		
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Resources',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			
		}
	}
</script>

<style>
	.resource_body{
		width: 90%;
		height: 1750px;
		margin-left: 5%;
		text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
		/* 背景半透明效果 */
		background: hsla(0,0%,100%,.5);
	}
	.res_title{
		width: 60%;
		height: 100px;
		/* background-color: aqua; */
		margin-left: 20%;
		text-align: center;
		line-height: 100px;
		font-size: 36px;
	}
	.res_body{
		width: 90%;
		margin-left: 5%;
		height: 1640px;
		border: 2px solid gray;
		border-radius: 20px;
		box-shadow: 5px 5px 2px 1px rgba(229, 230, 232, 0.2);
	}
	.res_content{
		text-indent: 2em;
		line-height: 2em;
		font-size: 24px;
	}
</style>