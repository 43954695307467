import {
	createStore
} from 'vuex'

// 创建一个新的 store 实例
export default new createStore({
	state() {
		return {
			articleList: [{
				id: 1,
				title: 'ajax返回String参数乱码',
				content: '解决方案：在requestMapping注解上添加produces={"text/html;charset=UTF-8;","application/json;"}',
				releaseTime: '2022-12-3'
			},{
				id: 2,
				title: 'eclipse断点不起作用',
				content: '<p>1.Eclipse设置断点，出现双斜杠<p><br/><img src="/asset1.png" /><br/><br/><p>2.解决方法：</p><br/><p style="text-indent:4rem">1.	快捷键：ctrl+alt+b</p><p style="text-indent:4rem">2.	点一下<img src="/asset3.png" style="width:30px;hegiht:30px;"/> （skip All Breakpoints//中文意思：屏蔽所有断点）</p><br/><img src="/asset2.png" />',
				releaseTime: '2022-12-3'
			},{
				id: 3,
				title: 'eclipseextension目录错位',
				content: '<h3>1. 出现场景：</h3><br/><p style="text-indent:4rem">换了一个新电脑，将我的eclipse安装文件直接复制到我的新电脑后，总会无缘无故在我的D盘根目录上创建一个eclipseextension目录。删了之后，当我再次重启，又会继续再该地方上创建。</p><br/><p style="text-indent:4rem">应该是由于我直接将该eclipse的安装文件直接复制，导致一些用户文件信息并未修改，还是延续了我之前的电脑的配置信息。（这一点我在创建eclipse工作区也发现默认配置是我上次配置的目录）</p><br/><img src="/extend1.png"/><br/><br/><p style="text-indent:6rem">1.在eclipse安装文件中找到一个叫p2的文件夹，进入该文件夹会发现有两个.info文件（pools.info和profiles.info）</p><br/><img src="/extend2.png"/><br/><br/><p style="text-indent:6rem">2.我们可以打开这两个文件发现，这两个文件存储的都是我之前的eclipse安装路径</p><br/><p style="text-indent:6rem">3.只需要将这两个文件删除（删除之前最好先把打开的eclipse关闭），再重新运行eclipse，让其重新生成这两个文件即可</p>',
				releaseTime: '2022-12-3'
			}],
			messageList: [{
				id: 1,
				name: '进步的花菜',
				content: '由于网站属于非经营性网站，不放置留言功能，可能由于站长技术水平，文章可能存在问题，欢迎给我邮箱发邮件，对于一些文章相关的建议将会在招贴部分展示',
				releaseTime: '2022-10-12'
			}, {
				id: 2,
				name: '进步的花菜',
				content: '贵有恒,何必三更眠五更起最无益,只益一日暴十日寒。',
				releaseTime: '2022-10-10'
			}]
		}
	}
})
