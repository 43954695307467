<template>
	<div id="all">
		<ParishHeader></ParishHeader>
		
		<div class="resource_body">
			<div class="res_title">
				背景音乐
			</div>
			<div class="res_body">
				<p class="res_content">非常抱歉，耽误您宝贵的几秒钟，该功能暂未实现,haha</p>
				<p class="res_content">网站页面背景音乐设置，用于设置背景音乐的开启和关闭，背景音乐的切换与选择</p>
			</div>
		</div>
		
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'BackgroundMusic',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			
		}
	}
</script>

<style>
</style>