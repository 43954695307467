<template>
	<!-- 总框架 -->
	<div id="all">
		<ParishHeader />
		<div class="main_body">
			<div class="main_left">
				<!-- 站长信息 -->
				<ParishAuthor />
				<!-- 分支 -->
				<ParishBranches />
				<!-- 辅助设置 -->
				<AuxiliarySettings />
			</div>
			<div class="main_center">
				<div class="center_top">
					<!-- 通知 -->
					<div class="notice">
						通知：{{notice}}
					</div>
					<div class="website_name">
						{{website.name}}
					</div>
					<div class="website_describe">
						{{website.describe}}
					</div>
				</div>
				<!-- 文章主体 -->
				<div class="center_content">
					<!-- 轮播图 -->
					<RotatingImage />
					<!-- 文章略看 -->
					<ArticleList />
				</div>
			</div>
			<div class="main_right">
				<!-- 文章top榜 -->
				<PopularArticle />
				<!-- 最新留言 -->
				<LatestMessage />
			</div>
		</div>
		<ParishFooter />
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	import ParishAuthor from "../components/ParishAuthor"
	import ParishBranches from "../components/ParishBranches"
	import AuxiliarySettings from "../components/AuxiliarySettings"
	import RotatingImage from "../components/RotatingImage"
	import LatestMessage from "../components/LatestMessage"
	import PopularArticle from "../components/PopularArticle"
	import ArticleList from "../components/ArticleList"
	import {reactive,ref} from 'vue'

	export default {
		name: 'ParishRoom',
		components: {
			ParishHeader,
			ParishFooter,
			ParishAuthor,
			ParishBranches,
			AuxiliarySettings,
			RotatingImage,
			LatestMessage,
			PopularArticle,
			ArticleList
		},
		setup() {
			let notice = ref('欢迎来到我的堂室 QAQ')
			let website = reactive({
				name: ref('我的堂室'),
				describe: ref('林花谢了春红，太匆匆。无奈朝来寒雨晚来风。')
			})

			return {
				notice,
				website
			}
		}
	}
</script>

<style>
	/* 总框架 */
	#all {
		width: 100%;
		height: 2000px;
		background-image: url(../assets/bg1.jpeg);
		background-repeat: no-repeat;
	}

	/* 主体部分 */
	.main_body {
		width: 90%;
		height: 1750px;
		margin-left: 5%;
		text-shadow: 0 1px 1px hsla(0, 0%, 100%, .3);
		/* 背景半透明效果 */
		background: hsla(0, 0%, 100%, .5);
	}

	/* 主体左部 */
	.main_left {
		width: 20%;
		height: 800px;
		float: left;
	}

	/* 主题中部 */
	.main_center {
		width: 60%;
		height: 1750px;
		float: left;
	}

	.center_top {
		width: 100%;
		height: 198px;
		border: 1px solid gray;
		background-color: #D39FC3;
	}

	.notice {
		width: 96%;
		height: 68px;
		background-color: #BD99BC;
		margin-left: 2%;
		margin-top: 10px;
		line-height: 68px;
		text-align: center;
		border-radius: 5px;
		color: #8D377E;
		font-weight: bold;
	}

	.website_name {
		width: 100%;
		height: 70px;
		font-size: 50px;
		line-height: 70px;
		text-indent: 20px;
		color: #8D377E;
		font-weight: bold;
		font-family: "楷体";
	}

	.website_describe {
		width: 100%;
		height: 60px;
		line-height: 60px;
		text-indent: 30px;
		color: #8A377E;
		font-size: 18px;
	}

	.center_content {
		width: 94%;
		height: 1550px;
		margin-left: 3%;
		margin-top: 10px;
	}

	/* 主体右部 */
	.main_right {
		width: 20%;
		height: 1750px;
		float: left;
	}
</style>
