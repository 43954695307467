<template>
	<!-- 头部 -->
	<div class="header">
		<div class="logo">
			<span id="logo">无心小花菜</span>
		</div>
		<div class="search">
			<input type="search" id="search_text" placeholder="站内文章搜索" @focus="showBorder" @blur="hiddenBorder" />
			<input type="button" id="search_btn" @click="toSerach"/>
		</div>
		<div class="header_nav">
			<ul>
				<li>
					<router-link to="/index">首页</router-link>
				</li>
				<li>
					<router-link to="/letterPen">信笔</router-link>
				</li>
				<li>
					<router-link to="/messageBoard">招贴</router-link>
				</li>
				<li>
					<router-link to="/tracks">足迹</router-link>
				</li>
				<li>
					<router-link to="/aboutme">关于</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ParishHeader',
		setup() {
			
			// 点击搜索框改变边框颜色
			function showBorder(e) {
				console.log(e.target)
				e.target.style.setProperty('border-width', '2px');
				e.target.style.setProperty('border-color', '#8A2BE2');
			}
			
			function hiddenBorder(e) {
				console.log(e.target)
				e.target.style.setProperty('border-width', '0px');
			}
			
			function toSerach(e){
				alert("搜索功能暂未实现")
			}
			
			return {
				showBorder,
				hiddenBorder,
				toSerach
			}
		}
	}
</script>

<style>
	/* 头部部分 */
	.header {
		width: 100%;
		height: 50px;
		background-color: black;
	}

	.logo {
		width: 15%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		float: left;
		color: white;
	}

	#logo {
		font-size: 30px;
		font-family: "仿宋";
	}

	/* 搜索框 */
	.search {
		width: 15%;
		height: 50px;
		float: left;
	}

	.search #search_text {
		width: 70%;
		height: 40px;
		border: 0px solid red;
		border-radius: 50px 0px 0px 50px;
		/* 关闭点击文本框时显示的外框 */
		outline: none;
		float: left;
		margin-top: 5px;
		text-indent: 10%;
		background-color: #F0F8FF;
	}

	/* 设置placeholder文本样式 */
	input::-webkit-input-placeholder {
		/* WebKit browsers*/
		color: #999;
		font-size: 16px;
		text-align: center;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18*/
		color: #999;
		font-size: 16px;
		text-align: center;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+*/
		color: #999;
		font-size: 16px;
		text-align: center;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+*/
		color: #999;
		font-size: 16px;
		text-align: center;
	}

	.search #search_btn {
		width: 20%;
		height: 40px;
		border: 0px solid red;
		border-radius: 0px 50px 50px 0px;
		margin-top: 5px;
		background: url(../assets/search_btn.png) no-repeat left;
		cursor: pointer;
		background-color: gray;
	}

	/* 头部导航栏 */
	.header_nav {
		margin-left: 20%;
		width: 40%;
		height: 50px;
		float: left;
		color: gray;
	}

	.header_nav li {
		width: 10%;
		text-align: center;
		margin-left: 8%;
		list-style-type: none;
		float: left;
		line-height: 50px;
		font-weight: 600;
		font-family: "楷体";
		font-size: 20px;
	}

	.header_nav li a{
		text-decoration: none;
		color: lightgray;
	}

	.header_nav li:hover {
		background-color: #F0F8FF;
	}

	.header_nav li:hover a {
		color: #000000;
	}
</style>
