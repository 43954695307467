<template>
	<div class="footer">
		<p class="footer_1">
			&copy;2022 &nbsp; Copyright &nbsp; &nbsp;
			<a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2022010547号</a>
		</p>
		<p class="footer_2">
			Powered by 无心小花菜&nbsp;|&nbsp; 图片来源网络，侵权删 <br />
		</p>
	</div>
</template>

<script>
	export default {
		name: 'ParishFooter',
	}
</script>

<style>
	/* 底部部分 */
	.footer {
		width: 90%;
		height: 198px;
		background: hsla(0, 0%, 100%, .5);
		text-align: center;
		margin-left: 5%;
		border-top: 2px solid gray;
	}

	.footer_1 {
		width: 100%;
		height: 80px;
		line-height: 80px;
		font-size: 20px;
	}

	.footer_1 a:link {
		color: black;
		text-decoration: none;
	}

	.footer_1 a:hover {
		text-decoration: underline;
	}

	.footer_1 a:visited {
		color: #54296E;
	}

	.footer_2 {
		width: 100%;
		height: 80px;
		line-height: 40px;
		font-size: 20px;
	}
</style>
