<template>
	<div id="all">
		<!-- 头部 -->
		<ParishHeader></ParishHeader>
		
		<!-- 主体 -->
		<div class="about_body">
			<div class="about_frame">
				<div class="about_left">
					<div class="about_left_title">
						关于网站
					</div>
					<div class="about_left_content" v-html="aboutContent">
						
					</div>
				</div>
			</div>
			
		</div>
		<!-- 尾部 -->
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Aboutme',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			let aboutContent = "<p>该网站的页面是网页设计课的一个作业，在2021年12月19日开始写的，写了有一个礼拜，搞了几张静态界面，到了2022年的11月底，再次打开这个'小项目'，便用最近学的vue将这个'小项目'重新捯饬了一下，也算旧瓶装新酒了。</p><p>由于并未使用一些快速建站的工具，小站现在还处于一个前端vue项目阶段，未完成后端的文本编辑功能，所以大部分都是setup解决所有，页面的一些小功能由于技术能力搞不来（理直气壮），由于没有正儿八经的在软件公司工作过,代码非常的凌乱,不够优雅,没有工程化的管理,也无形加大了难度,不过相信站长的cv能力，还是有功能完善的一天的。所以，后期的一个方向便是通过springBoot作为框架把后端api进行一个实现。</p><p>小站主要以分享一些我在编程学习碰到的bug、笔记等，如果有大佬的帮助，也是会更好的。最后，感谢您花费几分钟的时间把这段废话看完</p>"
			
			return{
				aboutContent
			}
		}
	}
</script>

<style>
	/* 总框架 */
	#aboutme{
		width: 100%;
		height: 1030px;
		background-image: url(../assets/bg1.jpeg);
		background-repeat: no-repeat;
		/* 背景图不随滚动条滚动 */
		background-attachment:fixed;
	}
	.about_body{
		width: 90%;
		height: 1750px;
		margin-left: 5%;
		/* 背景半透明效果 */
		background: hsla(0,0%,100%,.5);
	}
	.about_frame{
		width: 100%;
		height: 760px;
	}
	.about_left{
		width: 70%;
		margin-top: 30px;
		margin-left: 2%;
		border-radius: 10px;
		height: 1700px;
		float: left;
		border: 1px solid gray;
		box-shadow: 5px 5px 5px gray;
	}
	.about_left_title{
		width: 40%;
		height: 50px;
		font-size: 24px;
		font-weight: bold;
		line-height: 50px;
		margin-left: 2%;
	}
	.about_left_content{
		width: 95%;
		height: 300px;
		margin-left: 2%;
		text-indent: 2em;
		font-family: 宋体;
		font-size:20px;
		font-weight:bolder;
	}
	.about_left_content p{
		line-height: 2rem;
	}
</style>