<template>
	<div class="part_two">
		<div class="part_two_1">
			辅助设置
		</div>
		<ul>
			<li>
				<router-link to="/backgroundMusic">背景音乐</router-link>
			</li>
			<li>
				<router-link to="/pageDynamics">页面动效</router-link>
			</li>
			<li>
				<router-link to="/pageWizard">页面精灵</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'AuxiliarySettings',

	}
</script>

<style>
	.part_two {
		width: 100%;
		height: 200px;
		border-bottom: 2px solid gray;
	}

	.part_two_1 {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-indent: 1em;
		font-size: 20px;
		font-weight: 400;
		color: #8A2BE2;
	}

	.part_two li {
		list-style-type: none;
		width: 100%;
		height: 50px;
		font-size: 20px;
		font-weight: bold;
		color: #8A2BE2;
		line-height: 50px;
		text-align: center;
	}

	.part_two li a{
		text-decoration: none;
	}

	.part_two li:hover {
		background-color: #8D377E;
	}
	
	.part_two li:hover a{
		color: white;
	}
</style>
