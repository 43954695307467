<template>
	<div id="all">
		<!-- 头部 -->
		<ParishHeader></ParishHeader>
		<div class="resource_body">
			<div class="res_title">
				留言
			</div>
			<div class="res_body">
				<div class="mes_box">
					
				</div>
				<p class="mess_content">{{message[0].content}}</p>
				<div class="mess_footer">
					{{message[0].name}}<br />
					{{message[0].releaseTime}}
				</div>
			</div>
		</div>
		<!-- 尾部 -->
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import {
		useRouter,
		useRoute
	} from 'vue-router'
	import { computed } from 'vue'
	import { useStore } from 'vuex'
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Message',
		props:['id'],
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			const store = useStore()
			const route = useRoute()
			
			return{
				message: computed(() => store.state.messageList.filter(message=>{
					return message.id == route.query.mid
				}))
			}
		}
	}
</script>

<style>
	.mess_footer{
		width: 90%;
		height: 100px;
		font-size: 20px;
		line-height: 30px;
		font-family: "宋体";
		font-weight: bolder;
		text-align: right;
		margin-left: 5%;
		margin-top: 30px;
	}
	.mess_content{
		text-indent: 2rem;
		font-size: 24px;
		font-family: "楷体";
		font-weight: bolder;
	}
	.mes_box{
		width: 100%;
		height: 30px;
	}
</style>