<template>
	<div id="all">
		<ParishHeader></ParishHeader>
		
		<div class="resource_body">
			<div class="res_title">
				站长动向
			</div>
			<div class="res_body">
				<p class="res_content">最近大部分时间都在搞论文相关的东西，打算搞一个stm32定位配合百度地图API进行展示的一个微信小程序，诚邀stm32的大佬，给点指导给小老弟 QAQ</p>
			</div>
		</div>
		
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Drafts',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			
		}
	}
</script>

<style>
</style>