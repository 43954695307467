<template>
	<router-view></router-view>
</template>

<script>
	import ParishRoom from './components/ParishRoom.vue'
	import {
		useRouter,
		useRoute
	} from 'vue-router'

	export default {
		name: 'App',
		components: {
			ParishRoom
		},
		mounted() {
			// 鼠标滚动背景图移动
			window.onscroll = () => {
				this.bgScroll()
			}
			console.log("----------------------------------------------\n")
			console.log("小站受不了各位大佬们的攻击，求放过 QAQ\n")
			console.log("----------------------------------------------\n")
			this.pushParishRoom()
		},
		setup() {
			// 获取$router和$route
			const router = useRouter()
			const route = useRoute()

			function pushParishRoom() {
				router.push('/index')
			}

			function bgScroll() {
				let t = document.documentElement.scrollTop || document.body.scrollTop; //变量t就是滚动条滚动时，到顶部的距离
				let all = document.getElementById("all");
				if (t >= 315) { //当拖动到距离顶部100px处时，左边导航固定，不随滚动条滚动
					all.style.backgroundPosition = "bottom";
					all.style.backgroundAttachment = "fixed";
				} else {
					all.style.backgroundPosition = "top";
					all.style.backgroundAttachment = "scroll"; //恢复正常        
				}
			}
			return {
				bgScroll,pushParishRoom
			}
		}
	}
</script>

<style>
	* {
		margin: 0px;
		padding: 0px;
	}
</style>
