<template>
	<div class="latest_comments">
		<div class="comments_title">
			最新通知
		</div>
		<ul>
			<li v-for="(message,index) in latestMessages" :key="id">
				<img src="../assets/comment.png">&nbsp; 
				<router-link :to="`/message/show?mid=${message.id}`">{{message.content}}</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import { computed } from 'vue'
	import { useStore } from 'vuex'
	export default {
		name: 'LatestMessage',
		setup() {
			const store = useStore()
			
			return{
				latestMessages: computed(() => store.state.messageList.filter(message=>{
					return message.id <= 5
				}))
			}
		}
	}
</script>

<style>
	/* 最新评论 */
	.latest_comments {
		width: 100%;
		height: 400px;
	}

	.comments_title {
		width: 100%;
		height: 50px;
		font-size: 24px;
		font-family: "宋体";
		line-height: 50px;
		font-weight: bold;
		text-indent: 5%;
	}

	.latest_comments li {
		list-style-type: none;
		text-indent: 6%;
		width: 100%;
		height: 70px;
		line-height: 60px;
		overflow: hidden;
	}

	.latest_comments li img {
		vertical-align: middle;
	}

	.latest_comments li a {
		text-decoration: none;
		color: #000000;
	}

	.latest_comments li a:hover {
		text-decoration: none;
		color: white;
	}
</style>
