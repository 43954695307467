<template>
	<div id="all">
		<ParishHeader></ParishHeader>
		
		<div class="resource_body">
			<div class="res_title">
				三味书屋
			</div>
			<div class="res_body">
				<p class="res_content">最近看了下“大话数据结构”，还是非常有意思的 。</p>
			</div>
		</div>
		
		<ParishFooter></ParishFooter>
	</div>
</template>

<script>
	import ParishHeader from '../components/ParishHeader'
	import ParishFooter from "../components/ParishFooter"
	
	export default{
		name:'Bookcase',
		components:{
			ParishHeader,ParishFooter
		},
		setup() {
			
		}
	}
</script>

<style>
</style>