<template>
	<div class="content_rotation">
		<el-carousel :interval="4000" type="card" height="18vw" indicator-position="outside">
		      <el-carousel-item v-for="image in imageList" :key="id">
		        <img :src="image.src" class="image">
		      </el-carousel-item>
		    </el-carousel>
	</div>
</template>

<script>
	export default{
		name:'RotatingImage',
		setup() {
			let imageList = [{id:0,src:require("../assets/listen.png")},
			          {id:1,src:"/extend1.png"},
			          {id:2,src:require("../assets/dist.png")},
			          {id:3,src:"/extend2.png"},
					  {id:4,src:"/asset1.png"}]
					  
			return{
				imageList
			}
		}
	}
</script>

<style>
	.content_rotation {
		width: 100%;
		height: 300px;
		border-radius: 10px;
	}
	.image{
		width: 100%; 
		height: auto;
	}
</style>
